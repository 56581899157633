/* This file is for your main application css. */
/* LiveView specific classes for your customizations */
.invalid-feedback {
  color: #a94442;
  display: block;
  margin: -1rem 0 2rem;
}

.phx-no-feedback.invalid-feedback, .phx-no-feedback .invalid-feedback {
  display: none;
}

.phx-click-loading {
  opacity: 0.5;
  transition: opacity 1s ease-out;
}

.phx-disconnected {
  cursor: wait;
}

.phx-disconnected * {
  pointer-events: none;
}

.phx-modal {
  opacity: 1 !important;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.phx-modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.phx-modal-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.phx-modal-close:hover,
.phx-modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Alerts and form errors */
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert p {
  margin-bottom: 0;
}

.alert:empty {
  display: none;
}

.font_0 {
  font-family: "Vibur", cursive;
  font-size: 2.5rem;
}

.font_1 {
  font-family: "Arizonia", cursive;
}

.font_2 {
  font-family: "Cedarville Cursive", cursive;
}

.font_3 {
  font-family: "Coming Soon", cursive;
}

.font_4 {
  font-family: "Crafty Girls", cursive;
}

.font_5 {
  font-family: "Dawning of a New Day", cursive;
}

.font_6 {
  font-family: "Dokdo", cursive;
  font-size: 2.5rem;
}

.font_7 {
  font-family: "Gloria Hallelujah", cursive;
}

.font_8 {
  font-family: "Rock Salt", cursive;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

button:focus {
  background-color: #0069d9;
  border: 0.1rem solid #0069d9;
}

button:hover {
  background-color: #606c76;
  border-color: #606c76;
  color: #fff;
  outline: 0;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes daily-double {
  0% {
    transform: scale(0) rotate(40deg);
  }
  60% {
    transform: scale(1.8) rotate(-25deg);
  }
  85% {
    transform: scale(0.5) rotate(15deg);
  }
  95% {
    transform: scale(1.2) rotate(-5deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}
@keyframes slidedown {
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slidein {
  from {
    transform: translateY(25%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes popin {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes bg-rotate {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.phx-modal {
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
  display: grid;
  place-items: center;
}
.phx-modal .phx-modal-content {
  position: relative;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.5), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: none;
  animation: popin 200ms ease;
  animation: popin 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
  max-width: 800px;
  margin: 0;
}
.phx-modal .phx-modal-content .phx-modal-close {
  position: absolute;
  right: 8px;
  top: 0;
  font-size: 2rem;
}
.phx-modal .phx-modal-content button {
  margin-bottom: 10px;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes daily-double {
  0% {
    transform: scale(0) rotate(40deg);
  }
  60% {
    transform: scale(1.8) rotate(-25deg);
  }
  85% {
    transform: scale(0.5) rotate(15deg);
  }
  95% {
    transform: scale(1.2) rotate(-5deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}
@keyframes slidedown {
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slidein {
  from {
    transform: translateY(25%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes popin {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes bg-rotate {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.alert {
  z-index: 0;
  max-width: 900px;
  margin: 20px auto;
  margin-bottom: 30px;
  min-width: min(80vw, 900px);
  animation: slidedown 0.5s cubic-bezier(0.54, 1.85, 0.5, 1) forwards;
  transform: translateY(-400px);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.5), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border: 0;
}

.homepage .header,
body > header.header {
  position: relative;
  z-index: 2;
  background: #260894;
  background-image: linear-gradient(90deg, #b224ef 0%, #6713d2 48%, #4d94ff 100%);
  background-size: 400% 400%;
  animation: bg-rotate 30s ease infinite;
  animation-delay: -4s;
  padding: 10px;
}
.homepage .header .content,
body > header.header .content {
  max-width: 1400px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: end;
}
.homepage .header .content .home-link,
body > header.header .content .home-link {
  justify-self: start;
}
.homepage .header .content .home-link img,
body > header.header .content .home-link img {
  width: 24px;
  height: 24px;
  display: block;
}
.homepage .header .content .account-container,
body > header.header .content .account-container {
  position: relative;
}
.homepage .header .content .account-container .user-profile,
body > header.header .content .account-container .user-profile {
  display: flex;
}
.homepage .header .content .account-container .user-profile .email,
body > header.header .content .account-container .user-profile .email {
  color: white;
  margin-right: 5px;
  align-self: flex-end;
}
.homepage .header .content .account-container .user-profile svg,
body > header.header .content .account-container .user-profile svg {
  display: block;
}
.homepage .header .user-profile-dropdown,
body > header.header .user-profile-dropdown {
  opacity: 0;
  transform: translateY(-100vh);
  position: absolute;
  right: 0;
  background: white;
  z-index: 999;
  border-radius: 4px;
  top: 35px;
  min-width: 180px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.5), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.homepage .header .user-profile-dropdown.active,
body > header.header .user-profile-dropdown.active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 250ms ease;
}
.homepage .header .user-profile-dropdown ul,
body > header.header .user-profile-dropdown ul {
  list-style: none;
  margin: 0;
}
.homepage .header .user-profile-dropdown ul li,
body > header.header .user-profile-dropdown ul li {
  margin: 0;
  padding: 5px 20px;
  white-space: nowrap;
}
.homepage .header .user-profile-dropdown ul li:hover,
body > header.header .user-profile-dropdown ul li:hover {
  background: aliceblue;
}
.homepage .header .user-profile-dropdown ul a:first-child li,
body > header.header .user-profile-dropdown ul a:first-child li {
  padding-top: 10px;
  border-radius: 4px 4px 0 0;
}
.homepage .header .user-profile-dropdown ul a:last-child li,
body > header.header .user-profile-dropdown ul a:last-child li {
  padding-bottom: 10px;
  border-radius: 0 0 4px 4px;
}

.homepage .curved-div {
  position: relative;
  background: #260894;
  background-image: linear-gradient(90deg, #b224ef 0%, #6713d2 48%, #4d94ff 100%);
  background-size: 400% 400%;
  animation: bg-rotate 30s ease infinite;
  animation-delay: -4s;
  color: #FFF;
  text-align: center;
  overflow: hidden;
  font-family: "Roboto Slab", "Helvetica Neue", sans-serif;
  font-size: 5rem;
}
.homepage .curved-div .this-is-jeopardy {
  font-family: "Bungee", "Helvetica Neue", sans-serif;
  font-size: 6rem;
}
.homepage .curved-div svg {
  display: block;
}
.homepage .curved-div svg path {
  transform: scale(1.1) translateX(-3px);
}
.homepage .curved-div.upper {
  background: #f9f9f9;
  transform: translateY(2px);
}
.homepage .curved-div h1 {
  font-size: 6rem;
  margin: 0 20px;
  text-shadow: 2px 2px 2px #666;
}
.homepage .curved-div p {
  font-size: 1.6rem;
  margin: 0 5rem 20px 5rem;
  font-family: "Roboto Slab", "Helvetica Neue", sans-serif;
  color: #fff;
}

.header-bottom {
  z-index: 1;
  position: relative;
}

.curved-bottom-svg {
  display: block;
}

.curved-div-bottom {
  clip-path: url(#curved-bottom-clip-path);
  background-image: linear-gradient(90deg, #b224ef 0%, #6713d2 48%, #4d94ff 100%);
  background-size: 400% 400%;
  animation: bg-rotate 30s ease infinite;
  animation-delay: -4s;
  height: 40px;
}

@media only screen and (max-width: 400px) {
  .homepage .header .email,
body > header.header .email {
    font-size: 1.4rem;
  }
  .homepage .curved-div h1, .homepage .curved-div .this-is-jeopardy {
    font-size: 3rem;
    margin-bottom: 10px;
  }
  .homepage .curved-div p {
    font-size: 1.4rem;
  }
}
#bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -99999;
}

.homepage #bg {
  background: #f9f9f9;
}
.homepage .options {
  color: #222;
  padding: 20px;
  margin: auto;
  max-width: 300px;
}
.homepage .options form {
  margin: 0px;
}
.homepage .options input {
  background: #fff;
}
.homepage .alert.has-content ~ .options {
  animation: slidedown 0.5s cubic-bezier(0.54, 1.85, 0.5, 1) forwards;
}
.homepage.tv .header-instructions {
  font-family: "Roboto Slab", "Helvetica Neue", sans-serif;
  text-shadow: 2px 2px 2px #666;
  padding: 0px 10px;
  font-size: 5rem;
  list-style: none;
  margin: 20px 0 0 0;
}
.homepage.tv .share-prompt {
  margin-top: 20px;
  margin-bottom: 0px;
}
.homepage.tv .room-code-label {
  margin-top: 0px;
}
.homepage.tv .options ul {
  list-style: inside;
}

.pow .social-login {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  background: white;
}
.pow .social-login:hover {
  background: white;
}
.pow .social-login img, .pow .social-login svg {
  width: 24px;
  height: 24px;
  align-self: center;
}
.pow .social-login span {
  flex-grow: 1;
}
.pow .or {
  margin: 20px 0;
}

.main-container {
  display: flex;
  flex-direction: column;
}
.main-container form, .main-container input {
  margin-bottom: 0;
}
.main-container .top {
  background: aliceblue;
  margin-top: -50px;
  padding-top: 30px;
}
.main-container .alert.has-content ~ .top {
  margin-top: 0px;
  padding-top: 0px;
}

.quickstart {
  max-width: 500px;
  text-align: center;
  margin: 50px auto;
}

.community-header {
  text-align: center;
  margin-top: 50px;
}

.empty-state-create-link {
  align-self: center;
}

.choose_from_existing {
  max-width: 950px;
  padding: 0 25px;
  margin: 50px auto;
  width: 100%;
  text-align: center;
}
.choose_from_existing .create-your-own {
  margin-bottom: 1.5rem;
}
.choose_from_existing .community-header {
  margin-bottom: 50px;
}
.choose_from_existing .results tbody tr {
  cursor: pointer;
}
.choose_from_existing .results tbody tr:hover {
  background-color: aliceblue;
}
.choose_from_existing .search-and-filters {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 20px;
}
.choose_from_existing .search-and-filters .search-form {
  flex-grow: 1;
  max-width: 500px;
}
.choose_from_existing .search-and-filters .filters {
  margin-left: 20px;
}
.choose_from_existing .search-and-filters .filters form {
  display: inline-block;
}
.choose_from_existing .search-and-filters .filters label {
  display: inline;
  font-weight: 300;
  font-family: Helvetica, Arial, sans-serif;
}
.choose_from_existing .pagination {
  color: #4d6d8f;
  font-size: 1.4rem;
  text-align: left;
}
.choose_from_existing .pagination .next {
  float: right;
}
.choose_from_existing .no-results {
  margin: auto;
  width: 350px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 5px 15px lightgray;
  text-align: center;
}

.phx-modal .phx-modal-content {
  text-align: center;
  width: auto;
  min-width: 300px;
  margin: 0 20px 0 20px;
}
.phx-modal .phx-modal-content .content {
  margin-bottom: 20px;
}
.phx-modal .phx-modal-content .content .name {
  margin: -20px -20px 20px -20px;
  padding: 20px;
  background-color: #a1c4fd;
  background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
}